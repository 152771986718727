.App {
  text-align: center;
}

hr {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.navbar-spacer {
  height: 10px;
}

.navbar-brand-spacer {
  width: 30px;
}

.nav-link {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.top-nav-tab.active {
  background-color: #ccc !important;
}

.body-div {
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.brand-router {
  color: #111;
  text-decoration: none;

}

.nav-router {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  color: #111;
  text-decoration: none;
}

.nav-router:hover, .brand-router:hover {
  text-decoration: none;
  color: #111;
}

.nav-router-active {
  font-weight: 900; 
  border: 1px solid #111;
}

.signin-card {
  margin: 0 auto;
  max-width: 600px;
  min-width: 400px;
}

.signin-changepassword-spacer {
  height: 8px;
}

.signin-newpassword-title {
  font-weight: 700;
  display: inline-block;
  padding-bottom: 8px;
}

.signin-newpassword-good {
  color: green;
}

.signin-newpassword-bad {
  color: #df4759;
}

.admin-tab-body {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  max-width: 1024px;
}
.admin-rawsignups-spacer {
  height: 15px;
}

.admin-rawsignups-card-spacer {
  height: 12px;
}

.processed-signups-table > tbody > tr:first-child > td:first-child {
  width: 30%;
}

.biller-dropdown-menu .dropdown-menu {
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
}

.stopdbsync-buttons {
  width: 100%;
}

.stopdbsync-button-row-spacer {
  height: 12px;
}